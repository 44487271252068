import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Link as SamePageLink } from "react-scroll"
import BackgroundImage from "gatsby-background-image"
import Whatsapp from "../contact/whatsapp"

const Banner = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "pawprints.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BannerWrapper>
      <BackgroundImage Tag="div" fluid={data.file.childImageSharp.fluid}>
        <div className="hero-content">
          <div className="hero-info">
            <h1>{props.siteTitle}</h1>
            <p>
              Hondentrimsalon Astrid Zandbergen in Bunschoten-Spakenburg heeft
              al meer dan 15 jaar ervaring in het vakkundig knippen, effileren,
              plukken en scheren van honden! Puppy's mogen gratis kennis komen
              maken. Ook kunt u terecht voor wassen, tanden reinigen en nagels
              knippen. Liefde voor het vak maar nog meer voor de hond!
            </p>
            <div className="call-to-action">
              <Whatsapp />
              <SamePageLink to="page-start" smooth={true} duration={500}>
                Meer weten?
              </SamePageLink>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </BannerWrapper>
  )
}

const BannerWrapper = styled.section`
  .hero-content {
    background-color: rgba(2, 18, 45, 0.84);
    height: auto;
    min-height: 100vh;
    width: 100%;
    color: ${props => props.theme.color.primary};
    padding: ${props => props.theme.spacing.s} 0;
    @media (min-width: ${props => props.theme.breakpoint.desktop}) {
      padding: ${props => props.theme.spacing.xxl} 0;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${props => props.theme.color.tertiary};
      padding: ${props => props.theme.spacing.s};
      max-width: ${props => props.theme.size.xxxl};
      max-height: ${props => props.theme.size.xs};
      border: 2px solid transparent;
      border-radius: 50px;
      margin: ${props => props.theme.spacing.m} auto;
      color: ${props => props.theme.color.primary};
      cursor: pointer;
      &:hover {
        background-color: transparent;
        border: 2px solid ${props => props.theme.color.tertiary};
        color: ${props => props.theme.color.tertiary};
      }
      @media (min-width: ${props => props.theme.breakpoint.desktop}) {
        margin: ${props => props.theme.spacing.xxl} auto;
      }
    }

    .hero-info {
      @media (min-width: ${props => props.theme.breakpoint.desktop}) {
        width: ${props => props.theme.container.xl};
      }
      text-align: center;
      margin: 0 auto;
      p {
        text-align: justify;
        margin: 0 auto;
        width: ${props => props.theme.container.xxl};
        font-size: ${props => props.theme.fontSize.m};
        @media (min-width: ${props => props.theme.breakpoint.desktop}) {
          width: ${props => props.theme.container.xl};
        }
      }
      .call-to-action {
        @media (min-width: ${props => props.theme.breakpoint.desktop}) {
          width: ${props => props.theme.container.m};
          display: inline-flex;
        }
      }
    }
  }
`

export default Banner

import React from "react"
import styled from "styled-components"

import Activity from "../common/activity"

import Scissors from "../../images/svg/scissors.svg"
import Bath from "../../images/svg/bath.svg"
import Toothbrush from "../../images/svg/toothbrush.svg"

const Activities = () => {
  return (
    <ActivitiesWrapper>
      <ul>
        <Activity
          imageSource={Scissors}
          title="Knippen en trimmen"
          paragraph="Laat uw hond vakkundig bij mij knippen en trimmen!"
          linkToSection="/werkzaamheden/#knippen-en-trimmen"
        />
        <Activity
          imageSource={Bath}
          title="Wassen"
          paragraph="U kunt uw hond ook alleen laten kammen en wassen."
          linkToSection="/werkzaamheden/#wassen"
        />
        <Activity
          imageSource={Toothbrush}
          title="Tanden reinigen"
          paragraph="Uw hond heeft weer een stralende lach!"
          linkToSection="/werkzaamheden/#tanden-reinigen"
        />
      </ul>
    </ActivitiesWrapper>
  )
}

const ActivitiesWrapper = styled.section`
  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    padding: ${props => props.theme.spacing.m} 0;
  }
  margin: ${props => props.theme.spacing.m} auto;

  ul {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${props => props.theme.color.tertiary};
      padding: ${props => props.theme.spacing.s};
      max-width: ${props => props.theme.size.xxxl};
      max-height: ${props => props.theme.size.xs};
      border: 2px solid transparent;
      border-radius: 50px;
      margin: ${props => props.theme.spacing.m} auto;
      color: ${props => props.theme.color.primary};
      cursor: pointer;
      &:hover {
        background-color: transparent;
        border: 2px solid ${props => props.theme.color.tertiary};
        color: ${props => props.theme.color.tertiary};
      }
      @media (min-width: ${props => props.theme.breakpoint.desktop}) {
      }
    }
  }
`

export default Activities

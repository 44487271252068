import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Whatsapp = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "whatsApp.png" }) {
        childImageSharp {
          fixed(width: 50, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Link to="https://wa.me/31644434847" target="_blank">
      <Img fixed={data.file.childImageSharp.fixed} alt="Whatsapp Logo" />
      App/Bel mij gerust!
    </Link>
  )
}

export default Whatsapp

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Activity = props => {
  return (
    <>
      <ActivityWrapper>
        <img src={props.imageSource} alt={props.altText} />
        <h3>{props.title}</h3>
        <p>{props.paragraph}</p>
        <Link to={props.linkToSection}>Bekijk foto's</Link>
      </ActivityWrapper>
    </>
  )
}

const ActivityWrapper = styled.li`
  text-align: center;
  list-style: none;
  flex-grow: 1;
  flex-basis: 100%;

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    flex-basis: calc(100% / 3);
  }
`

export default Activity

import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TextBlockImage = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "puppy-in-box.jpg" }) {
        childImageSharp {
          fixed(width: 400, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <TextBlockImageWrapper id={props.id}>
      <Img fixed={data.file.childImageSharp.fixed} alt="Puppy in box" />
      <div>
        <h2>Gratis kennismaking voor puppies!</h2>
        <p>
          Puppy’s mogen gratis kennis komen maken om even rustig aan de omgeving
          te wennen. Tips nodig over de verzorging van uw pup? Ik kan u daar bij
          helpen. Vraag gerust!
        </p>
      </div>
    </TextBlockImageWrapper>
  )
}

const TextBlockImageWrapper = styled.section`
  color: ${props => props.theme.color.primary};
  background: ${props => props.theme.color.tertiary};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.spacing.m};
  div {
    margin-top: ${props => props.theme.spacing.m};
  }
  img {
    border-radius: 100px;
  }
  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    width: ${props => props.theme.container.xl};
    flex-direction: row;
  }
  text-align: center;
  margin: 0 auto;
  p {
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    margin: 0 auto;
    width: ${props => props.theme.container.xxl};
    font-size: ${props => props.theme.fontSize.m};
    @media (min-width: ${props => props.theme.breakpoint.desktop}) {
      width: ${props => props.theme.container.xl};
    }
  }
`

export default TextBlockImage

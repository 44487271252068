import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import Banner from "../components/common/banner"
import TextBlock from "../components/common/textBlock"
import Activities from "../components/common/activities"
import TextBlockImage from "../components/common/textBlockImage"
import GoogleMap from "../components/contact/googleMaps"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home - Hondentrimsalon Astrid Zandbergen" />
      <>
        <Banner siteTitle={`Hondentrimsalon Astrid Zandbergen`} />
        <TextBlockImage id="page-start" />
        <Activities />
        <TextBlock
          title="Over Mij"
          paragraph="Mijn naam is Astrid, ik ben met veel plezier alweer ruim 15 jaar geleden deze hondentrimsalon in Bunschoten-Spakenburg gestart! 
          Ik vind het werken met dieren, en speciaal met honden geweldig! Tijdens een trimbeurt wordt uw hond gewassen, 
          geschoren en/of geknipt. Daarnaast kunnen de tanden worden gereinigd en de nagels geknipt. 
          Je kunt mij altijd bellen en/of appen met vragen over uw hond of voor het maken van afspraken."
        />
        <GoogleMap />
      </>
    </Layout>
  )
}

export default IndexPage

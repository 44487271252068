import React from "react"
import styled from "styled-components"

const TextBlock = props => {
  return (
    <TextBlockWrapper id={props.id}>
      <h2>{props.title}</h2>
      <p>{props.paragraph}</p>
    </TextBlockWrapper>
  )
}

const TextBlockWrapper = styled.section`
  color: ${props => props.theme.color.primary};
  background: ${props => props.theme.color.tertiary};
  padding: ${props => props.theme.spacing.m};
  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    width: ${props => props.theme.container.xl};
  }
  text-align: center;
  margin: 0 auto;
  p {
    text-align: justify;
    margin: 0 auto;
    width: ${props => props.theme.container.xxl};
    font-size: ${props => props.theme.fontSize.m};
    @media (min-width: ${props => props.theme.breakpoint.desktop}) {
      width: ${props => props.theme.container.xl};
    }
  }
`

export default TextBlock

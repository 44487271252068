import React from "react"
import styled from "styled-components"

const GoogleMap = () => {
  return (
    <GoogleMapWrapper>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2442.503563781967!2d5.375746715686424!3d52.252400179764344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c63f4cdf64f8eb%3A0x69d440796b93f978!2sKerkstraat%2028%2C%203751%20AR%20Bunschoten-Spakenburg!5e0!3m2!1snl!2snl!4v1607597675628!5m2!1snl!2snl"
        width="100%"
        height="460"
        frameBorder={0}
        style={{ border: 0 }}
        allowFullScreen={false}
        aria-hidden="false"
        tabIndex={0}
      ></iframe>
    </GoogleMapWrapper>
  )
}

const GoogleMapWrapper = styled.section``

export default GoogleMap
